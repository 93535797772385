.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar {
  height: 100px;
  z-index: 50;
  width: 100vw;
  position: fixed;
}

.mobileNav{
  background-color: #3B138D;
  color: white;
  border: 1px solid white;
}

.mobileNav > li{
  padding-left: 15px;
}

.mobileNav > li > h5{
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
}

.navbar-light .navbar-nav .nav-link{
  color: white;
  font-family: 'Ubuntu', sans-serif;
}

.footer {
  text-align: center;
  background-image: linear-gradient(to right, #3D138F, #3B138D);
  color: black;
  padding: 10px 0 10px 40px;
  height: 20%;
}

.search-container{
  max-width: 400px;
  min-width: 200px;
  margin-top: 20px;
  align-self: center;
}

.search-button{
  margin-left: 10px;
}

.research {
  margin: 150px 5% 5% 5%;
  display: flex;
  align-items: flex-start;
}

.research-sectors {
  display: none;
}

.nav-pills .nav-link.active{
  color: #3B138D;
  background-color: white;
  font-weight: bold;
  border: 1px solid #3B138D;
}

.research-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /*padding-right: 4px;*/
}

.research-cards-cardContainer{
  width: 350px;
  margin-bottom: 70px;
  height: 100%;


}

.research-cards-cardContainer > a{
  text-decoration: none;
  color: black;
}

.card-title{
  font-size: 17px;
  text-align: center;
  font-weight: bold;
}

.card-subtitle{
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.research-cards-iconContainer{
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

}

.research-cards-iconContainer__icon{
  height: 175px;
  width: 175px;
  border-radius: .25rem;
  object-fit: contain;
}

.research-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.details-container{
  margin-top: 150px;
}

.details-overviewContainer {
  width: 85%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details-overviewContainer__header {
  width: 65vw;
}

.details-detailsContainer {
  background-image: url("https://res.cloudinary.com/doaftkgbv/image/upload/v1585510524/concrete-texture_jedfhl.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 0;
  margin: 10px 0;
}

.details-detailsContainer__table {
  width: 85%;
  background-color: white;
  margin: 40px auto;
  border-radius: 20px;
  border: 1px solid #DBDADB;
}

.row {
  justify-content: space-between;
  margin: 0 10px;
  flex-wrap: nowrap;
}

.details-disclosure {
  background-color: transparent;
  width: 85%;
  margin: 0 auto;
}

.details-disclosure > h5{
  font-size: 14px;
}

.details-disclosure > p {
  font-size: 11px;
  width: 100%;
}

.details-detailsContainer__table-companyInfo{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 15px;
}

.tab-content{
  margin-top: 10px;
}


.research-cards-cardContainer__spacer {
  height: 50px;
}

.carousel {
  margin: 0 auto;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active{
  display: flex;
  justify-content: center;
}

.carousel-container{
  margin: 100px auto;
  /*background-color: #6a518f;*/
  width: 50%;
}

.carousel-indicators > li {
  background-color: #755d8d;
}

.carousel-indicators .active {
  background-color: #3B138D;
}


.carousel-control-next-icon {
  background-image: url("https://res.cloudinary.com/doaftkgbv/image/upload/v1585584448/next_1_jkjvoi.svg");
}

.carousel-control-prev-icon {
  background-image: url("https://res.cloudinary.com/doaftkgbv/image/upload/v1585585188/back_f3yvbf.svg");
}

.nav-tabs .nav-link.active {
  border-radius: 20px 0 0 0;
  border: none;
  border-bottom: 3px solid #3B138D;
}

.disclaimer {
  margin-top: 10px;
  font-size: 11px;
}

.btn-primary {
  background-color: #3B138D;
  border-color: #3D138F;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: white;
  color: #3B138D;
  border-color: #3D138F;
  font-weight: bold;
}


.card{
  box-shadow: 2px 4px 9px #888888;

}

.welcomeBackground {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-image: url("https://res.cloudinary.com/doaftkgbv/image/upload/v1585589941/whitediamond__2X_w2jgh3.png");
  justify-content: space-between;
  /*height: 100%;*/
  /*align-self: stretch;*/
  height: 75vh;

}

.callToAction {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*margin-left: 40px;*/ /* May need to add to desktop*/
  margin-top: 150px;
  padding: 0 5px;
  text-align: center;
}

.callToAction-slogan > h1{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 3em;

}

.callToAction-slogan > h3, h5{
  font-family: 'Ubuntu', sans-serif;
}

.homepageAsset{
  display: none;
}

.dropdown-menu {
  background-color: #3B138D;
  border: 1px solid white;
}

.dropdown-menu > a{
  text-decoration: none;
}


.dropdown-item {
  color: white;
}

.dropdown-item:hover {
  color: #3B138D;

}

.companyDetails{
  display: flex;
  justify-content: space-between;
}

.tabletNav {
  display: none;
}

.footer{
  color: white;
  padding: 75px;
}

.footer > p > a{
  font-weight: bold;
}

.favsContainer {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favsContainer > h1{
  margin-bottom: 50px;
}

.favs-card-container {
  width: 95vw;
}

.chartContainer{
  padding-right: 15px;
}


.canvasjs-chart-canvas{
  padding-right: 15px;
}
/*==================================

      TABLET & DESKTOP RESPONSIVE

==================================*/
@media (min-width: 768px){
  .research-sectors {
    /*padding: 3px;*/
    display: block;
    width: 100%;
    background-color: #3B138D;
    color: white;
    border-radius: 15px;
  }

  .research-sectors > p{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  .research-cards-cardContainer{
    max-width: 350px;
    margin-bottom: 70px;
    height: 100%;

  }

  .research-cards-iconContainer{
    width: 200px;
    height: 200px;
  }

  .mobileNav{
    display: none;
  }

  .tabletNav {
    display: flex;
  }

  .navbar-light .navbar-nav .nav-link{
    color: grey;
  }

  .details-overviewContainer {
    flex-direction: row;
  }

  .welcomeBackground {
    flex-direction: row;
    flex-wrap: nowrap;
    height: 75vh;

  }

  .homepageAsset{
    display: block;
    align-self: center;
  }

  .callToAction {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*margin-left: 40px;*/ /* May need to add to desktop*/

    text-align: left;
  }

  .details-disclosure > p {
    width: 40%;
  }

  .companyDetails{
    width: 40vw;
  }

  .details-detailsContainer__table-companyInfo{
    flex-direction: row;
  }
}